import React, { useMemo } from "react"
import PageRoot from "../../components/PageRoot"
import { useTranslation } from "react-i18next"
import ContainerWithPadding from "../../components/ContainerWithPadding"
import { Col } from "antd"
import { graphql } from "gatsby"
import HeroHeader from "../../components/HeroHeader"
import AtsGrid from "../../components/AtsGrid"
import { renderCarouselItem } from "../../utils"

const OnDemandCourses = ({ data }) => {
  const { t } = useTranslation()

  const { courses } = data.ats
  const mostRecentCourse = [courses[0]]

  const coursesHeroHeading = useMemo(() => {
    return (
      <HeroHeader
        className={"on-demand-hero-header"}
        dataSource={mostRecentCourse}
        showSlider={false}
      />
    )
  }, [courses])

  const onDemandCoursesRow = useMemo(() => {
    return (
      <AtsGrid
        title={t("label:onDemandCourses")}
        dataSource={courses}
        renderItem={renderCarouselItem}
        emptyItem={t("message:noOnDemandCourses")}
        isVertical={true}
        colSettings={{
          xs: 24,
          md: 12,
          lg: 8,
          xl: 6,
          xxl: 4,
        }}
      />
    )
  }, [courses])

  return (
    <PageRoot title={t("label:onDemand")}>
      {coursesHeroHeading}
      <ContainerWithPadding size="full" style={{ paddingTop: 0 }}>
        <Col span={24}>
          <div className={"on-demand-courses-grid"}>{onDemandCoursesRow}</div>
        </Col>
      </ContainerWithPadding>
    </PageRoot>
  )
}

export const query = graphql`
  query onDemandCoursesQuery {
    ats {
      courses(
        order_by: { created_at: desc }
        where: { state: { _eq: PUBLISHED }, price_policy: { _eq: ON_DEMAND } }
      ) {
        ...PreviewCardCourse
        header_image {
          ...SvgImage
        }
        wallpaper {
          ...Wallpaper
        }
        portrait {
          is_public
          s3_key
        }
      }
    }
  }
`

export default OnDemandCourses
